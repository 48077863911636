import React from 'react'
import { makeStyles } from '@krowdy-ui/core'

const ViewError = ({ title = 'Not Found' }) => {
  const classes = useStyles()

  return (
    <div className={classes.pageNot}>
      {/* <ImageBee alt='' src='https://s3.amazonaws.com/cdn.krowdy.com/media/images/krowdy-empty.svg' /> */}
      {title}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  pageNot: {
    fontSize  : 18,
    fontWeight: 500,
    marginTop : theme.spacing(1.5),
    textAlign : 'center'
  }
}))

export default ViewError
